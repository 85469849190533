<template>
  <div class="popup_wrap" style="width:800px; height:520px;">
    <button
      type="button"
      class="layer_close"
      @click="popClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        {{ $t('msg.ONEX010P100.032') }}
      </h1>
      <div class="content_box">
        <!--        <h2 class="content_title">Booking</h2>-->
        <DEXT5Upload
          ref="dextupload_docPop"
          id="dextupload_docPop"
          width="100%"
          height="200px"
          :single="singleFileYn"
          :file-info="parentInfo"
          :file-ext="fileExt"
          :category="categoryInfo.length > 0"
          :category-info="categoryInfo"
        />
      </div>
    </div>
    <div class="mt10 text_center">
      <a class="button blue lg" @click="popClose()">{{ $t('msg.VOSD100.041') }}</a>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/DEXT5Upload'

export default {
  name: 'DocUploadPop',
  components: { DEXT5Upload },
  props: {
    singleFileYn: {
      type: Boolean,
      default: false
    },
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    },
    categoryInfo: {
      type: Array,
      default: () => []
    },
    fileExt: {
      type: String,
      default: 'bmp,jpg,jpeg,png,pdf,xls,xlsx,doc,docx,zip,rar',
      required: false
    }
  },
  created () {
    // console.log(this.parentInfo)
    // console.log('--- categoryInfo ---')
    // console.log(this.categoryInfo)
    // test
    // this.fileInfo.guid[i], this.fileInfo.originalName[i], '/', this.fileInfo.size[i], this.fileInfo.fileId[i], uploadId
    // this.fileInfo = [{ guid: '21915747-F71E-930A-D23F-FD1689A1A8E6', originalName: '스크린샷 2021-08-03 오후 3.22.04.png', size: '16958', fileId: '123', status: 'complete' }, { guid: '129C42D8-0F13-AE72-2D32-85D94A8393FC', originalName: '스크린샷 2021-08-04 오후 1.15.45.png', size: '84930', fileId: '124', status: 'complete' }]
  },
  methods: {
    setFileInfo: function (parentInfo) {
      // let $vm = this
      console.log('#######' + parentInfo)

      if (this.$refs.dextupload_docPop !== undefined) {
        this.$refs.dextupload_docPop.setFileInfo(parentInfo)
        // this.$emit('arrange')
      }
    },
    async popClose () {
      const isUploading = this.$refs.dextupload_docPop.isUploading()

      if (isUploading) {
        // 아직 업로드 중.....
        console.log('아직 업로드 중')
        return
      }

      let file = this.$refs.dextupload_docPop.getFileInfo()

      // 카테고리 선택이 필요한 경우
      if (this.categoryInfo.length > 0 && file.length > 0) {
        let isCategory = true
        file.forEach(f => {
          if (f.category === '') {
            isCategory = false
          }
        })

        if (!isCategory) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: 'Please select category' })
          return
        }
      }
      //~ 카테고리 선택이 필요한 경우

      this.$refs.dextupload_docPop.ResetDext()
      this.$emit('set-dext-file-info', file)

      // this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
      this.$emit('close')
      // return false
    }
  }
}
</script>
