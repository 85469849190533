var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb10", attrs: { id: "frm" } },
    [
      [
        _c("h2", { staticClass: "content_title" }, [_vm._v("D/O Requester")]),
        _vm._v(" "),
        _c("table", { staticClass: "tbl_row" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _vm.memberDetail.userCtrCd === "JP"
                ? _c("th", [_vm._v("Bank Slip & A/N")])
                : _c("th", [_vm._v("Payment & LOA")]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "div",
                  {
                    staticClass: "filebox",
                    staticStyle: { display: "inline" },
                    attrs: { id: "file_att" },
                  },
                  [
                    _vm.jpUploadParam.length === 0
                      ? _c(
                          "a",
                          {
                            staticClass: "button sm gray file",
                            on: {
                              click: function ($event) {
                                return _vm.uploadJpPopup()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.CSBK100.205")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.jpUploadParam.length > 0
                      ? _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          _vm._l(_vm.jpUploadParam, function (jp) {
                            return _c(
                              "div",
                              {
                                key: jp.fileId,
                                staticStyle: { display: "inline" },
                              },
                              [
                                _c("span", { staticClass: "tbl_icon attach" }),
                                _c("a", { staticClass: "text_link" }, [
                                  _vm._v(_vm._s(jp.originalName)),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "tbl_icon attdel",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeJpUploadFile(
                                          _vm.selectedJpBlNo
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("X")]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.memberDetail.userCtrCd === "JP"
                      ? _c("div", { staticStyle: { display: "inline" } }, [
                          _c("input", {
                            staticClass: "col-7",
                            attrs: {
                              type: "text",
                              id: "tempText",
                              name: "tempText",
                              value:
                                "添付は1件のみですので、A/Nと振込書は1つのPDFにしてください。",
                              readonly: "true",
                            },
                          }),
                        ])
                      : _vm.memberDetail.userCtrCd === "TH"
                      ? _c("div", { staticStyle: { display: "inline" } }, [
                          _c("input", {
                            staticClass: "col-9",
                            attrs: {
                              type: "text",
                              id: "tempText",
                              name: "tempText",
                              value:
                                "Please upload Payment slip/Company name, address, and TAX ID number for issuing receipt/Proforma Invoice",
                              readonly: "true",
                            },
                          }),
                        ])
                      : _vm.memberDetail.userCtrCd === "MY"
                      ? _c("div", { staticStyle: { display: "inline" } }, [
                          _c("input", {
                            staticClass: "col-4",
                            attrs: {
                              type: "text",
                              id: "tempText",
                              name: "tempText",
                              value: "Please upload Payment and LOA",
                              readonly: "true",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.020")))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.reqUsrNm,
                      expression: "formData.reqUsrNm",
                    },
                  ],
                  staticClass: "col-6",
                  attrs: { type: "text", id: "reqUsrNm", name: "reqUsrNm" },
                  domProps: { value: _vm.formData.reqUsrNm },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "reqUsrNm", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.021")))]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.mbpOfcNo,
                      expression: "formData.mbpOfcNo",
                    },
                  ],
                  staticClass: "col-6",
                  attrs: {
                    type: "text",
                    id: "mbpOfcNo",
                    name: "mbpOfcNo",
                    maxlength: "12",
                  },
                  domProps: { value: _vm.formData.mbpOfcNo },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "mbpOfcNo", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm.memberDetail.userCtrCd !== "JP"
              ? _c("tr", [
                  _c("th", [_vm._v("E-mail Address")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.emlAddr,
                          expression: "formData.emlAddr",
                        },
                      ],
                      staticClass: "col-6",
                      attrs: {
                        type: "text",
                        id: "mbpEmlAddr",
                        name: "mbpEmlAddr",
                      },
                      domProps: { value: _vm.formData.emlAddr },
                      on: {
                        blur: function ($event) {
                          return _vm.checkValidationEmail("mbpEmlAddr")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.formData, "emlAddr", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.023")))]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.blNoStrings) + " ")]),
            ]),
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.024")))]),
              _vm._v(" "),
              _c("td", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.reqCont,
                      expression: "formData.reqCont",
                    },
                  ],
                  attrs: {
                    id: "reqCont",
                    name: "reqCont",
                    placeholder: _vm.$t("msg.ONEX070G040.071"),
                  },
                  domProps: { value: _vm.formData.reqCont },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "reqCont", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm.formData.reqBizCatCd === "06"
              ? _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX070G040.026")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _vm._l(_vm.formData.items, function (item, index) {
                        return [
                          _c("input", {
                            key: index,
                            attrs: {
                              type: "checkbox",
                              id: "porDly_" + index,
                              name: "pordly",
                            },
                            domProps: { value: _vm.formData.items.blNo },
                            on: {
                              click: function ($event) {
                                return _vm.changePolDlyArr(item.blNo)
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              key: "label_" + index,
                              staticClass: "mr20",
                              attrs: { for: "porDly_" + index },
                            },
                            [_c("span"), _vm._v(_vm._s(item.blNo))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.007"))),
        ]),
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(1),
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.008")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.009")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.010")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.011")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.012")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.013")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.014")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.015")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P060.016")))]),
            ]),
          ]),
          _vm.payList !== undefined && _vm.payList.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.payList, function (pay, idx) {
                  return _c("tr", { key: `${idx}_${pay.blNo}_${pay.frtCd}` }, [
                    pay.rowSpanBlNo === "Y"
                      ? _c("td", { attrs: { rowspan: pay.blCount } }, [
                          _vm._v(" " + _vm._s(pay.blNo) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        class: [
                          "text_left",
                          pay.rowSpanBlNo !== "Y" ? "border_left" : "",
                        ],
                      },
                      [_vm._v(" " + _vm._s(pay.frtNm) + " ")]
                    ),
                    _c("td", [_vm._v(_vm._s(pay.occrCurCd))]),
                    _c("td", { staticClass: "text_right" }, [
                      _vm._v(_vm._s(pay.occrAmt)),
                    ]),
                    _c("td", [_vm._v(_vm._s(pay.frtUnitNm))]),
                    _c("td", [_vm._v(_vm._s(pay.rton))]),
                    _c("td", [_vm._v(_vm._s(pay.occrExrt))]),
                    _c("td", [_vm._v(_vm._s(pay.locCurCd))]),
                    _c("td", { staticClass: "text_right" }, [
                      _vm._v(_vm._s(pay.locAmt)),
                    ]),
                  ])
                }),
                0
              )
            : _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.017"))),
                  ]),
                ]),
              ]),
          _c("tfoot", [
            _c("tr", [
              _c("th", { attrs: { colspan: "8" } }, [
                _vm._v(_vm._s(_vm.$t("msg.ONIM099P060.018"))),
              ]),
              _c("th", { staticClass: "text_right" }, [
                _vm._v(_vm._s(_vm.payTotal)),
              ]),
            ]),
          ]),
        ]),
      ],
      _vm.uploadFlag === "Y" && _vm.uploadJp === "Y"
        ? _c(
            "win-layer-pop",
            { ref: "uploadPop", staticClass: "upload_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.jpUploadParam,
                      "single-file-yn": _vm.singleFileYn,
                    },
                    on: {
                      "set-dext-file-info": _vm.arrangeUploadParam,
                      close: _vm.closePopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadFlag === "Y" && _vm.uploadTax === "Y"
        ? _c(
            "win-layer-pop",
            { ref: "uploadPop", staticClass: "upload_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: {
                      "parent-info": _vm.taxUploadParam,
                      "single-file-yn": true,
                    },
                    on: {
                      "set-dext-file-info": _vm.arrangeUploadParam,
                      close: _vm.closePopup,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadFlag === "N"
        ? _c(
            "win-layer-pop",
            { staticClass: "outer_popup_dim" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popParams },
                    on: {
                      close: _vm.closePopup,
                      selectFunc: _vm.selectFunc,
                      callback: _vm.selectFunc,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "170" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "26%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }