import Send from '../../trans.client.js'

export default {
    // Payment 정보 조회
    popPaymentList (params) {
      return Send({
        method: 'get',
        url: '/trans/do-request/payment',
        params: params
      })
    },
    // Payment 정보 저장
    InsertPayment (params) {
      return Send({
        method: 'post',
        url: '/trans/do-request/payment',
        data: params
      })
    },
    // Payment 정보 삭제
    payDelete (params) {
      return Send({
        method: 'delete',
        url: '/trans/do-request/payment',
        data: params
      })
    },
    // Payment 첨부파일 리스트 조회
    popInputPayUpload (payNo) {
      return Send({
        method: 'get',
        url: '/trans/do-request/payment/' + payNo + '/attach'
      })
    },
    // Payment 첨부파일 저장
    popPayUpload (params) {
      return Send({
        method: 'post',
        url: '/trans/do-request/payment/' + params.payNo + '/attach',
        data: params
      })
    },
    // Payment 상태값 체크
    findPayReqYn (params) {
      return Send({
        method: 'get',
        url: '/trans/do-request/pay-req-yn',
        params: params
      })
    },
    doReqSave (params) {
      return Send({
        method: 'post',
        url: '/trans/do-request',
        data: params
      })
    }
}
