<template class="bl_payment_pop">
  <div id="frm" class="mb10">
    <template>
      <h2 class="content_title">D/O Requester</h2> <!-- B/L 발행 요청인 -->
      <table class="tbl_row">
        <colgroup>
          <col width="170"><col>
        </colgroup>
        <tbody>
          <tr>
            <th v-if="memberDetail.userCtrCd==='JP'">Bank Slip & A/N</th> <!-- 振込明細 Upload -->
            <th v-else>Payment & LOA</th> <!-- 振込明細 Upload -->
            <td>
              <div class="filebox" id="file_att" style="display: inline">
                <a
                  @click="uploadJpPopup()"
                  v-if="jpUploadParam.length === 0"
                  class="button sm gray file"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
                <div v-if="jpUploadParam.length > 0" style="display: inline">
                  <div v-for="jp in jpUploadParam" :key="jp.fileId" style="display: inline">
                    <span class="tbl_icon attach"></span>
                    <a class="text_link">{{ jp.originalName }}</a>
                    <a class="tbl_icon attdel" @click="removeJpUploadFile(selectedJpBlNo)">X</a>
                  </div>
                </div>
                <div v-if="memberDetail.userCtrCd === 'JP'" style="display: inline">
                  <input type="text" id="tempText" name="tempText" class="col-7" value="添付は1件のみですので、A/Nと振込書は1つのPDFにしてください。" readonly="true">
                </div>
                <div v-else-if="memberDetail.userCtrCd === 'TH'" style="display: inline">
                  <input type="text" id="tempText" name="tempText" class="col-9" value="Please upload Payment slip/Company name, address, and TAX ID number for issuing receipt/Proforma Invoice" readonly="true">
                </div>
                <div v-else-if="memberDetail.userCtrCd === 'MY'" style="display: inline">
                  <input type="text" id="tempText" name="tempText" class="col-4" value="Please upload Payment and LOA" readonly="true">
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <th>{{ $t('msg.ONEX070G040.020') }}</th> <!-- 신청인 -->
            <td>
              <input type="text" id="reqUsrNm" name="reqUsrNm" v-model="formData.reqUsrNm" class="col-6">
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX070G040.021') }}</th> <!-- 연락처 -->
            <td>
              <input type="text" id="mbpOfcNo" name="mbpOfcNo" maxlength="12" v-model="formData.mbpOfcNo" class="col-6">
            </td>
          </tr>

          <tr v-if="memberDetail.userCtrCd !== 'JP'">
            <th>E-mail Address</th> <!-- email 주소 -->
            <td>
              <input type="text" id="mbpEmlAddr" name="mbpEmlAddr" v-model="formData.emlAddr" class="col-6" @blur="checkValidationEmail('mbpEmlAddr')">
            </td>
          </tr>

          <tr>
            <th>{{ $t('msg.ONEX070G040.023') }}</th> <!-- B/L No. -->
            <td>
              {{ blNoStrings }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.ONEX070G040.024') }}</th> <!-- 요청내용(선택) -->
            <td>
              <!-- <textarea id="reqCont" name="reqCont" v-model="formData.reqCont" placeholder="O.B/L 관련 요청 및 입금 세부사항 기재 부탁드립니다."></textarea> -->
              <textarea id="reqCont" name="reqCont" v-model="formData.reqCont" :placeholder="$t('msg.ONEX070G040.071')"></textarea>
            </td>
          </tr>
          <tr v-if="formData.reqBizCatCd === '06'">
            <th>{{ $t('msg.ONEX070G040.026') }}</th> <!-- POR & DLY 표기여부 -->
            <td>
              <template v-for="(item, index) in formData.items">
                <input type="checkbox" :id="'porDly_' + index" name="pordly" :value="formData.items.blNo" :key="index" @click="changePolDlyArr(item.blNo)">
                <label :for="'porDly_' + index" class="mr20" :key="'label_' + index"><span></span>{{ item.blNo }}</label>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <h2 class="content_title">{{ $t('msg.ONIM099P060.007') }}</h2><!-- Freight information -->

      <table class="tbl_col">
        <colgroup>
          <col style="width:12%">
          <col style="width:26%">
          <col style="width:8%">
          <col style="width:10%">
          <col style="width:8%">
          <col style="width:8%">
          <col style="width:8%">
          <col style="width:10%">
          <col style="width:10%">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('msg.ONIM099P060.008') }}</th><!-- B/L No. -->
            <th>{{ $t('msg.ONIM099P060.009') }}</th><!-- Freight & Charge -->
            <th>{{ $t('msg.ONIM099P060.010') }}</th><!-- CUR -->
            <th>{{ $t('msg.ONIM099P060.011') }}</th><!-- Rate -->
            <th>{{ $t('msg.ONIM099P060.012') }}</th><!-- Unit -->
            <th>{{ $t('msg.ONIM099P060.013') }}</th><!-- Per -->
            <th>{{ $t('msg.ONIM099P060.014') }}</th><!-- Ex.Rate -->
            <th>{{ $t('msg.ONIM099P060.015') }}</th><!-- Local CUR -->
            <th>{{ $t('msg.ONIM099P060.016') }}</th><!-- Local Amount -->
          </tr>
        </thead>
        <tbody v-if="payList !== undefined && payList.length > 0">
          <tr
            v-for="(pay, idx) in payList"
            :key="`${idx}_${pay.blNo}_${pay.frtCd}`"
          >
            <td
              v-if="pay.rowSpanBlNo === 'Y'"
              :rowspan="pay.blCount"
            >
              {{ pay.blNo }}
            </td>
            <td
              :class="['text_left', pay.rowSpanBlNo !== 'Y' ? 'border_left' : '']"
            >
              {{ pay.frtNm }}
            </td>
            <td>{{ pay.occrCurCd }}</td>
            <td class="text_right">{{ pay.occrAmt }}</td>
            <td>{{ pay.frtUnitNm }}</td>
            <td>{{ pay.rton }}</td>
            <td>{{ pay.occrExrt }}</td>
            <td>{{ pay.locCurCd }}</td>
            <td class="text_right">{{ pay.locAmt }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9">{{ $t('msg.ONIM099P060.017') }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="8">{{ $t('msg.ONIM099P060.018') }}</th>
            <th class="text_right">{{ payTotal }}</th>
          </tr>
        </tfoot>
      </table>
    </template>
    <!-- 팝업 관련 -->
    <win-layer-pop v-if="uploadFlag === 'Y' && uploadJp === 'Y'" ref="uploadPop" class="upload_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="jpUploadParam"
        :single-file-yn="singleFileYn"
        @set-dext-file-info="arrangeUploadParam"
        @close="closePopup"
      />
    </win-layer-pop>

    <win-layer-pop v-if="uploadFlag === 'Y' && uploadTax === 'Y'" ref="uploadPop" class="upload_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="taxUploadParam"
        :single-file-yn="true"
        @set-dext-file-info="arrangeUploadParam"
        @close="closePopup"
      />
    </win-layer-pop>

    <win-layer-pop v-if="uploadFlag === 'N'" class="outer_popup_dim">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
        @callback="selectFunc"
      />
    </win-layer-pop>
    <!--  <div class="mt10 text_center">
      <a class="button blue lg" href="#" @click.prevent="fnPayment">발행요청</a>
      <a class="button gray lg" href="#" @click.prevent="$emit('close')">취소</a>
    </div> -->
  </div>
</template>

<script>
import auth from '@/api/services/auth'
import SurrenderRequestPop from '@/pages/trans/popup/SurrenderRequestPop'/* Surrender 요청서 팝업 */
import WinLayerPop from '@/pages/trans/popup/WinLayerPop' /* 팝업 관련 */
import DocUploadPop from '@/pages/trans/popup/DocUploadPop' /* 팝업 관련 */

import transCommon from '@/api/rest/trans/transCommon'
import blPayment from '@/api/rest/trans/blPayment'
import doPayment from '@/api/rest/trans/do'
import payment from '@/api/rest/trans/payment'

import { rootComputed } from '@/store/helpers'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'

export default {
  name: 'DoRequsetTarget',
  components: {
    SurrenderRequestPop,
    WinLayerPop,
    DocUploadPop,
    EAutoCompletePlace,
    'bl-modity-noti-pop': () => import('@/pages/trans/popup/BLModifyNotiPop')
  },
  props: {
    blList: {
      type: Array,
      required: true
    },
    issStstus: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      lang: '',
      type: '', //old : 국가별로 화면이 다르기 때문에 type으로 제어
      blNoStrings: '',
      reqBizCatCdList: [
        { cd: '06', cdNm: this.$t('msg.ONEX070G040.029') }, //Original B/L
        { cd: '02', cdNm: this.$t('msg.ONEX070G040.030') }, //Surrendered B/L
        { cd: '03', cdNm: this.$t('msg.ONEX070G040.031') } //Sea Way Bill
      ],
      formData: {
        blNoArr: [],
        items: [],
        reqBizCatCd: '',
        polDlyBlNoArr: [],
        issPlcCdArr: [],
        issPlcCd: '',
        reqCont: '', //요청 remark
        uploadFileInfos: [],
        payType: '',
        uploadjpTaxFileInfos: [],
        jpFileInfo: {},
        emlAddr: ''
      },
      customComponent: null, //팝업 관련
      selectFunc: null, //팝업 관련
      popParams: {}, //팝업 관련
      uploadParam: [], //팝업 관련,
      uploadJpParam: [], //팝업 관련,
      uploadTaxParam: [], //팝업 관련,
      uploadFlag: 'N',
      uploadJp: 'N',
      uploadTax: 'N',
      popFlag: false,
      etcFlag: 'N',
      tvo: {},
      blStrings: ['', ''],

      selectedJpBlNo: '',
      selectedTaxBlNo: '',
      jpBlNoList: [],
      taxBlNoList: [],
      jpUploadParam: [],
      jpSuccBlNo: [],
      taxUploadParam: [],
      taxSuccBlNo: [],

      payList: [],
      payTotal: 0,
      params: {
        blNo: '',
        blNoArr: '',
        arrBlNo: [],
        linkPayNo: '',
        linkYn: 'N',
        doYn: 'Y',
        payStsCd: '',
        paymentUser: {
          payNo: '',
          blNo: '',
          shirPicNm: '',
          shirTelNo: '',
          shirEmlAddr: '',
          evdcmtrUpldYn: '',
          rmk: ''
        },
        paySubmitYn: 'N'
      },
      newPay: '',
      oldPayYn: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    blList: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      console.log('@@@@ mounted')
      let s = this.$t('msg.ONEX070G040.012')
      console.log('@@@@ s: ', s)
      this.blStrings[0] = s.substring(0, s.indexOf('※'))
      this.blStrings[1] = s.substring(s.indexOf('※'))
    })
  },
  created () {
    this._ = require('lodash')
    //국가별 분기 셋팅
    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
      this.setNationalType(this.lang)
      this.formData.reqUsrNm = this.auth.userName
    }

    this.init()
  },
  methods: {
    async init () {
      const TH = this

      if (this.auth !== undefined) {
        await auth.info(this.auth.jwt).then(res => {
          const obj = res.data
          let usrTel = ''

          if (this.$ekmtcCommon.isNotEmpty(obj)) {
            if (this.$ekmtcCommon.isNotEmpty(obj.cel1)) {
              usrTel += obj.cel1
            }
            if (this.$ekmtcCommon.isNotEmpty(obj.cel2)) {
              usrTel += obj.cel2
            }
            if (this.$ekmtcCommon.isNotEmpty(obj.cel3)) {
              usrTel += obj.cel3
            }
            if (this.$ekmtcCommon.isNotEmpty(obj.cel4)) {
              usrTel += obj.cel4
            }

            this.formData.mbpOfcNo = usrTel
            this.formData.emlAddr = obj.userEmail
          }
        })
      }

      let resultArr = []
      this.blList.map((item) => {
        if (!resultArr.includes(item.blNo)) {
          resultArr.push(item.blNo)
        }
      })
      this.formData.blNoArr = resultArr

      //BL No. selectBox 세팅하기
      // succFlag 추가
      if (resultArr.length > 0) {
        for (let i = 0; i < resultArr.length; i++) {
          let tmpBlInfo = {
            blNo: resultArr[i],
            succFlag: 'N'
          }

          // blNoList에 넣기
          TH.jpBlNoList.push(tmpBlInfo)
          TH.taxBlNoList.push(tmpBlInfo)
        }
      }

      //BL_NO가 1개일 경우 selectBox 초기화셋팅
      if (TH.jpBlNoList.length === 1) {
        TH.selectedJpBlNo = TH.jpBlNoList[0].blNo

        this.getJpFileInfo()
      }

      if (TH.taxBlNoList.length === 1) {
        TH.selectedTaxBlNo = TH.taxBlNoList[0].blNo

        this.getTaxFileInfo()
      }

      this.formData.reqBizCatCd = '07'
      this.setReqBizCatCd('07')

      //BL String화
      this.blNoStrings = this.formData.blNoArr.toString()

      await this.getPaymentList()
    },
    //국가별 코드 셋팅(화면 및 rest api 분기용)
    setNationalType (lang) {
      if (lang === 'KOR') {
        this.type = 'KO'
      } else {
        if (this.memberDetail !== undefined) {
          if (this.memberDetail.userCtrCd === 'TH') {
            this.type = 'TH'
          } else if (this.memberDetail.userCtrCd === 'JP') {
            this.type = 'JP'
          } else {
            this.type = 'EN'
          }
        }
      }
    },
    //발행요청
    async fnPayment (payType) {
      //입금방식 셋팅
      this.formData.payType = payType

      //지역선택값 국외인 경우 발행지 셋팅
      if (this.etcFlag === 'Y') {
        this.formData.ctrCd = this.tvo.ctrCd
        this.formData.issPlcCd = this.tvo.plcCd
      }
      //업로드 오브젝트 셋팅
      this.formData.uploadFileInfos = this.uploadParam

      const result = await doPayment.insertDoRequestEtc(this.formData)
      return result
    },
    checkDoInputData () {
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.formData.emlAddr)) {
        msg = this.$t('msg.MYIN010G010.051')
        isOk = false
      }

      if (this.$ekmtcCommon.isEmpty(this.formData.mbpOfcNo)) {
        msg = this.$t('msg.MYIN010G010.050')
        isOk = false
      }

      if (this.$ekmtcCommon.isEmpty(this.formData.reqUsrNm)) {
        msg = this.$t('msg.ONEX060P012.080')
        isOk = false
      }

      if (this.memberDetail.userCtrCd !== 'VN' && this.jpUploadParam.length === 0) {
        msg = this.$t('art.CMATK210') // Or msg.ONEX050G020.040
        isOk = false
      }

      this.$ekmtcCommon.alertDefault(msg)

      return isOk
    },
    //발행요청2
    async fnPayment2 (req) {
      if (!this.checkDoInputData()) {
        return
      }

      //입금방식 셋팅
      this.formData.payType = req.payType
      this.formData.payAcctList = req.payAcctList

      //지역선택값 국외인 경우 발행지 셋팅
      this.formData.ctrCd = this.memberDetail.userCtrCd
      // this.formData.issPlcCd = req.podPortCd
      this.formData.podPortInfos = {
        podPortCd: req.podPortCd,
        blNo: req.blNo
      }

      //업로드 오브젝트 셋팅
      this.formData.uploadFileInfos = this.uploadParam

      // 파일데이터 파라이터 초기화해줌
      this.formData.uploadjpTaxFileInfos = []

      // 파라미터 세팅
      for (var jp of this.jpSuccBlNo) {
        jp.fileInfo.forEach((jpf, idx) => {
          jpf.atchFileCatCd = jp.atchFileCatCd
          jpf.blNo = jp.blNo
          jpf.atchFileSeq = (idx + 1)

          this.formData.uploadjpTaxFileInfos.push(jpf)
        })
      }

      // console.log('this.formData.issPlcCd @@@@@ ', this.formData.issPlcCd)

      for (var tax of this.taxSuccBlNo) {
        tax.fileInfo[0].atchFileCatCd = tax.atchFileCatCd
        tax.fileInfo[0].blNo = tax.blNo

        this.formData.uploadjpTaxFileInfos.push(tax.fileInfo[0])
      }

      const result = await doPayment.insertDoRequestEtc(this.formData)
      return result
    },
    //POL & DLY 체크
    changePolDlyArr (blNo) {
      let index = this.formData.polDlyBlNoArr.indexOf(blNo)
      if (index > -1) {
        this.formData.polDlyBlNoArr.splice(index, 1)
      } else {
        this.formData.polDlyBlNoArr.push(blNo)
      }
    },
    openPopup (compNm, alertFlag) {
      this.customComponent = compNm

      if (this.selectedJpBlNo === '' && alertFlag === 'J') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047'))
        return
      }

      if (this.selectedTaxBlNo === '' && alertFlag === 'T') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G090.047'))
        return
      }

      if (this.uploadFlag === 'Y') {
        this.$ekmtcCommon.layerOpen('.bl_payment_pop .upload_popup_dim')
      } else {
        this.$ekmtcCommon.layerOpen('.bl_payment_pop .outer_popup_dim')
      }
    },
    closePopup (obj) {
      console.log('##### close #####')

      if (this.customComponent === 'SurrenderRequestPop' && obj !== undefined) {
        this.uploadParam = obj
        this.customComponent = null
      }

      if (this.uploadFlag === 'Y') {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.bl_payment_pop .upload_popup_dim')
      } else {
        this.$ekmtcCommon.layerClose('.bl_payment_pop .outer_popup_dim')
      }
    },
    uploadFilePop () {
      const uploadParam = this.uploadParam

      //팝업 플래그 처리
      this.uploadFlag = 'Y'
      this.uploadJp = 'N'

      this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop')
    },
    uploadJpPopup () {
      this.uploadFlag = 'Y'
      this.uploadJp = 'Y'
      this.uploadTax = 'N'
      this.singleFileYn = false
      const uploadParam = this.jpUploadParam

      this.$set(this, 'jpUploadParam', uploadParam)

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.jpFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.jpFileInfo })

      //   if (this.uploadJpParam.length === 0) {
      //     this.uploadJpParam.push({ ...this.formData.jpFileInfo })
      //   }
      // }

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.taxFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.taxFileInfo })
      // }

      // this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop', 'J')
    },
    uploadTaxPopup () {
      this.uploadFlag = 'Y'
      this.uploadTax = 'Y'
      this.uploadJp = 'N'
      this.singleFileYn = true
      const uploadParam = this.taxUploadParam

      this.$set(this, 'taxUploadParam', uploadParam)

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.taxFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.taxFileInfo })

      //   if (this.uploadTaxParam.length === 0) {
      //     this.uploadTaxParam.push({ ...this.formData.taxFileInfo })
      //   }
      // }

      // if (this.$ekmtcCommon.isNotEmpty(this.formData.jpFileInfo.fileId)) {
      //   uploadParam.push({ ...this.formData.jpFileInfo })
      // }

      // this.$set(this, 'uploadParam', uploadParam)
      this.openPopup('DocUploadPop', 'T')
    },
    arrangeUploadParam (file) {
      const TH = this
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)

      console.log('arrangeUploadParam @@@@', file)
      if (TH.uploadJp !== 'Y' && TH.uploadTax !== 'Y') {
        TH.uploadParam.map((item) => (item.newFlag = item.new))
      } else {
          if (file.length > 0) {
              if (TH.uploadTax === 'Y') {
                this.taxUploadParam.map((item) => (item.newFlag = item.new))

                TH.taxUploadParam = this._.cloneDeep(file)

                //succbl배열에 넣어주기
                TH.taxSuccBlNo.push(
                  {
                    blNo: TH.selectedTaxBlNo,
                    agreeYn: 'Y',
                    disabled: 'Y',
                    fileInfo: TH.taxUploadParam,
                    atchFileCatCd: '02'
                  }
                )
                //succFlag바꿔주기
                TH.taxBlNoList.forEach(item => {
                  if (item.blNo === TH.selectedTaxBlNo) {
                    item.succFlag = 'Y'
                  }
                })
              } else {
                this.jpUploadParam.map((item) => (item.newFlag = item.new))

                TH.jpUploadParam = this._.cloneDeep(file)

                //succbl배열에 넣어주기
                TH.jpSuccBlNo.push(
                  {
                    blNo: TH.selectedJpBlNo,
                    agreeYn: 'Y',
                    disabled: 'Y',
                    fileInfo: TH.jpUploadParam,
                    atchFileCatCd: '01'
                  }
                )
                //succFlag바꿔주기
                TH.jpBlNoList.forEach(item => {
                  if (item.blNo === TH.selectedJpBlNo) {
                    item.succFlag = 'Y'
                  }
                })
              }
          } else {
              if (TH.uploadTax === 'Y') {
                TH.taxUploadParam = {}
              } else {
                TH.jpUploadParam = {}
              }
          }
      }
    },
    removeUploadFile () {
      this.uploadParam.splice(0, 1)
      this.customComponent = null
    },
    removeJpUploadFile (blNo) {
      this.jpUploadParam.splice(0, 1)

      const removeIndex = this.jpSuccBlNo.findIndex(el => el.blNo === blNo)

      if (removeIndex >= 0) {
        this.jpSuccBlNo.splice(removeIndex, 1)
        // this.setAllSaveYn()
      }
      this.customComponent = null
    },
    removeTaxUploadFile (blNo) {
      this.taxUploadParam.splice(0, 1)

      const removeIndex = this.taxSuccBlNo.findIndex(el => el.blNo === blNo)

      if (removeIndex >= 0) {
        this.taxSuccBlNo.splice(removeIndex, 1)
        // this.setAllSaveYn()
      }
      this.customComponent = null
    },
    //국가,포트 변경시 값 셋팅(자동완성)
    changePort (vo) {
      console.log('vo!!', vo)
      const def = {
        ctrCd: '',
        plcCd: ''
      }
      const tvo = { ...def, ...vo }
      this.tvo = tvo
    },
    fnOpenPop (flag) {
      if (flag === 'O') {
        this.popFlag = true
      } else {
        this.popFlag = false
      }
    },
    //초기화
    reset () {
      this.lang = ''
      this.type = ''
      this.blNoStrings = ''
      this.formData = {
        blNoArr: [],
        items: [],
        reqBizCatCd: '',
        polDlyBlNoArr: [],
        issPlcCdArr: [],
        issPlcCd: '',
        reqCont: ''
      }
      this.customComponent = null
      this.selectFunc = null
      this.popParams = {}
      this.uploadParam = []
      this.popFlag = false
      this.jpUploadParam = []
      this.taxUploadParam = []
    },
    //벨리데이션을 위한 카테고리코드 emit
    setReqBizCatCd (reqBizCatCd) {
      const elemMessage = document.querySelectorAll('.tooltip_essen')
      const elemForm = document.querySelectorAll('.input_error')

      let len = elemMessage.length

      for (let i = 0; i < len; i++) {
        elemMessage[i].style.display = 'none'
      }

      len = elemForm.length

      for (let i = 0; i < len; i++) {
        this.$ekmtcCommon.elemRemoveClass(elemForm[i], 'input_error')
      }
      this.$emit('setReqBizCatCd', reqBizCatCd)

      if (reqBizCatCd !== '06') {
        this.$set(this.formData, 'polDlyBlNoArr', [])
      }
    },
    setEtcFlag () {
      if (this.formData.issPlcCd === 'ETC') {
        this.etcFlag = 'Y'
      } else {
        this.etcFlag = 'N'
      }
    },
    emitData (type) {
      let res = null
      const TH = this

      switch (type) {
        case 'bl':
          this.formData.uploadFileInfos = this.uploadParam

          for (var jp of this.jpSuccBlNo) {
            jp.fileInfo[0].atchFileCatCd = jp.atchFileCatCd
            jp.fileInfo[0].blNo = jp.blNo

            this.formData.uploadjpTaxFileInfos.push(jp.fileInfo)
          }

          for (var tax of this.taxSuccBlNo) {
            tax.fileInfo[0].atchFileCatCd = tax.atchFileCatCd
            tax.fileInfo[0].blNo = tax.blNo

            this.formData.uploadjpTaxFileInfos.push(tax.fileInfo[0])
          }

          res = JSON.parse(JSON.stringify(this.formData))
          break
      }
      return res
    },
    async goBLModify (blNo) {
      let auth = ''
      const authParams = {
        bizCd: 'BL',
        rqRno: blNo,
        eiCatCd: ''
      }
      await transCommon.isHaveAuth(authParams).then(async res => {
        auth = res.data
      })
      if (auth !== 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
        return
      }

      this.fnNoticePop(blNo)
    },
    async fnNoticePop (blNo) {
      let bkgNo = ''
      await transCommon.getBkgNo(blNo).then(res => {
        bkgNo = res.data
      })
      this.selectFunc = this.callBackNotice
      this.popParams = {
        bkgNo: bkgNo,
        blNo: blNo
      }

      this.openPopup('bl-modity-noti-pop')
    },
    callBackNotice (vo) {
      this.$ekmtcCommon.layerClose('.bl_payment_pop .outer_popup_dim')
      this.$router.push({ path: '/working/detail/bl', name: 'bl', params: { blNo: vo.blNo, bkgNo: vo.bkgNo } }).catch(() => {})
    },
    goFreetime (blNo) {
      const query = {
        eiCatCd: 'O',
        dtKnd: 'BL',
        num: blNo
      }
      this.$router.push({ name: 'FreeTimeRequest', query: query })
    },
    setRcqCont (reqCont, rcvProcCatCd) {
      console.log('>>>>> BLPaymentTarget : ' + reqCont + ', ' + rcvProcCatCd)
      if (rcvProcCatCd === '02') {
        if (this.formData.reqCont.indexOf(reqCont) < 0) {
          // 요청내용(선택) 값 변경
          this.formData.reqCont = reqCont + '\r\n' + this.formData.reqCont
        }
      }
    },
    //파일 여부 가져오기
    getJpFileInfo () {
      const TH = this
      const selectedBlNo = TH.selectedJpBlNo

      TH.jpUploadParam = []

      if (TH.jpSuccBlNo.length > 0) {
        TH.jpSuccBlNo.forEach(item => {
          if (selectedBlNo === item.blNo) {
            TH.jpUploadParam = item.fileInfo
          }
        })
      }
    },
    //파일 여부 가져오기
    getTaxFileInfo () {
      const TH = this
      const selectedBlNo = TH.selectedTaxBlNo

      TH.taxUploadParam = []

      if (TH.taxSuccBlNo.length > 0) {
        TH.taxSuccBlNo.forEach(item => {
          if (selectedBlNo === item.blNo) {
            TH.taxUploadParam = item.fileInfo
          }
        })
      }
    },
    checkValidationCharger (id) {
      const frm = document.querySelector('#frm')
      let isOk = true
      // essentialYn: 필수입력사항, telFirstYn: 지역번호 첫째자리 0체크

      const selector = frm.querySelector('#' + id)
      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.alertDefault('필수값을 입력해주세요.')
      }

      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)
      let isOk = true
      if (
        selector !== undefined &&
        selector !== null &&
        this.$ekmtcCommon.isNotEmpty(selector.value)
      ) {
        if (selector.value.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
            isOk = false
          }
        } else {
          if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            isOk = false
          }
        }
      }
      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.VOSD100.043')
        ) // 이메일 체크
      }

      return isOk
    },
    async getPaymentList () {
      const blNo = this.blNoStrings.replace(/,/g, ':')

      if (!this.$ekmtcCommon.isEmpty(blNo)) {
        this.params.blNo = blNo

        await payment.popPaymentList(this.params)
          .then(response => {
            const resp = response.data
            const payList = resp.payList
            const paymentUser = resp.paymentUser

            this.payList = []

            if (payList !== undefined && payList.length > 0) {
              // 핸들링 컨사이니와 요청자가 같은 경우 체크
              const doInfos = this.blList
              let convPayList = []

              for (var pay of payList) {
                for (var info of doInfos) {
                  if (pay.blNo === info.blNo) {
                    if (this.$ekmtcCommon.isNotEmpty(info.hcneCstNo) && (info.hcneCstNo === this.memberDetail.cstCd)) {
                      pay.frtInfoYn = 'Y'
                    } else {
                      pay.frtInfoYn = 'N'
                    }
                  }
                }
              }

              for (let i = 0; i < payList.length; i++) {
                let item = payList[i]

                if (item.frtInfoYn === 'Y') {
                  if (i === 0 || item.blNo !== payList[i - 1].blNo) {
                    item.rowSpanBlNo = 'Y'
                  }

                  this.payTotal += Number(item.locAmt)

                  item.occrExrt = this.$ekmtcCommon.isNotEmpty(item.occrExrt) && Number(item.occrExrt) > 0 ? String(item.occrExrt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
                  item.locAmt = this.$ekmtcCommon.isNotEmpty(item.locAmt) && Number(item.locAmt) > 0 ? String(item.locAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
                  item.rton = this.$ekmtcCommon.isNotEmpty(item.rton) && Number(item.rton) > 0 ? String(item.rton).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
                  item.occrAmt = this.$ekmtcCommon.isNotEmpty(item.occrAmt) && Number(item.occrAmt) > 0 ? String(item.occrAmt).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'

                  convPayList.push(item)
                }
              }

              this.payList = convPayList

              this.payTotal = String(this.payTotal).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }

            if (paymentUser !== undefined) {
              this.params.paymentUser = { ...this.params.paymentUser, ...paymentUser }
            }

            this.newPay = resp.newPay
            this.oldPayYn = resp.oldPayYn

            // this.payReqYn = resp.payReqYn
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style scoped>
  .btn_error_area {width: 110px; font-size: 12px; text-align: center;}
  .btn_error_area + .btn_error_area {margin-top: 5px;}
  .bl-highlight {
    font-weight: bold;
    font-size: 20px;
  }
  .border_left_none {
    height: 45px;
  }
  .content_title span {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
</style>
